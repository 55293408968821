import { absoluteUrl } from 'utils/urlGenerator';

type GetExpertLink = (runtime: Runtime, alias: ATExpertType['alias']) => string;

/**
 * Функция генерации ссылки на страницу эксперта
 * @param runtime - объект рантайма
 * @param alias - никнейм эксперта
 */
// TODO(NEWS-11862): Избавиться от runtime в сторону более простого подхода
export const getExpertLink: GetExpertLink = (runtime, alias) =>
  absoluteUrl(runtime.domainConfig, runtime.project.id, `/experts/${alias}/`);
